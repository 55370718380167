import React from "react";
import Helmet from "react-helmet";
import firebase from "gatsby-plugin-firebase";
import moment from "moment";
import Layout from "../layout";
import config from "../../blogposts/data/SiteConfig";
import SEO from "../components/SEO";
import ViewCounter from "../components/ViewCounter";

const TimelineItem = ({ data }) => (
  <div className="timeline-item">
    <div className="timeline-item-content">
      <span
        className="tag"
        style={{ background: data.category.color, borderRadius: "10%" }}
      >
        {data.category.tag}
      </span>
      <time>{data.date}</time>
      <p>{data.text}</p>
      {data.link && (
        <a href={data.link.url} target="_blank" rel="noopener noreferrer">
          {data.link.text}
        </a>
      )}
      <span className="circle" />
    </div>
  </div>
);

const initialData = [
  {
    category: {
      color: "#018f69",
      tag: "medium",
    },
    date: "2019-02-14",
    link: {
      text: "Read more",
      url: "https://medium.com/@sharma.pratap22/artificial-intelligence-and-machine-learning-a-career-everyone-should-look-in-for-935a086523e5",
    },
    text: "Wrote my first blog post ever on Medium",
  },
  {
    category: {
      color: "#5183f5",
      tag: "blog",
    },
    date: "2020-04-15",
    link: {
      text: "Check it out here",
      url: "https://pratapsharma.io/happy-number-data-coding-challenge/",
    },
    text: "Created my blog and wrote my first blog post on it",
  },
];

const Timeline = () => {
  const [timelineData, setTimeline] = React.useState(initialData);

  React.useEffect(() => {
    firebase
      .database()
      .ref("timeline")
      .once("value")
      .then((snapshot) => {
        setTimeline(snapshot.val() || []);
      });
  }, []);
  return (
    <Layout>
      <SEO />
      <Helmet title={`${config.siteTitle} Timeline : Road to glory!`} />
      <span style={{ display: "none" }}>
        <ViewCounter id="timeline" />
      </span>
      <div className="timeline-container">
        <h1>My journey so far!</h1>
        <p>
          Below you can see a timeline of my professional accomplishments.
          <br />I hope that this will motivate you to follow your dreams and to
          write down your own accomplishments along the way
        </p>
        <div className="timeline-wrapper">
          {timelineData
            .sort(
              (a, b) => moment(b.date).format("X") - moment(a.date).format("X")
            )
            .map((data, idx) => (
              <TimelineItem data={data} key={`timeline-${idx}`} />
            ))}
        </div>
        <p>
          <strong>
            This page is updated regularly and you are welcomed to come back and
            check my progress!
          </strong>
        </p>
      </div>
    </Layout>
  );
};

export default Timeline;
